<template>
  <div class="emojis">
    <a class="emoji" v-for="(emoji, name, idx) in $global.dictionary.emojis" @click="addEmoji(emoji)" :key="idx">
      <span>{{ emoji }}</span>
    </a>
  </div>
</template>

<script>
export default {
  props: ["target"],
  methods: {
    addEmoji(emoji, target = this.target) {
      if (typeof target === "string") target = document.querySelector(target);
      if (target) target.focus();
      document.execCommand("insertHTML", false, emoji);
    },
  },
};
</script>

<style lang="scss" scoped>
.emojis {
  position: absolute;
    bottom: 60px;
    user-select: none;
    z-index: 65;
    display: flex;
    flex-wrap: wrap;
    gap: 7.5px;
    background: #f7f7f7;
    padding: 10px 5px;
  .emoji {
    cursor: pointer;
    transition: 0.25s ease-in-out;
    display: inline-flex;
    font-size: 130%;
  }
  .emoji:hover {
    text-decoration: none;
    transform: scale(1.3);
  }
}
</style>
