<template>
  <div class="modalLikes">
    <Modal>
      <template v-slot:header>
        <div class="modalLikes__header modalLikes__containerRow modalLikes__containerRow--end">
          <a class="modalLikes__close" @click="$emit('close', false)">
            <Iconic name="close" />
          </a>
        </div>
      </template>
      <template v-slot:body>
        <ul class="modalLikes__container modalLikes__body" id="modalLikes__body">
          <li class="modalLikes__row modalLikes__containerRow" v-for="(user, idx) in likes" :key="idx">
            <span class="modalLikes__avatar modalLikes__containerRow">
              <Avatar class="modalLikes__avatar" :user="user.userId" />
            </span>
            <span class="modalLikes__name ">
              <p>{{ user.userId.artisticName || user.userId.user }}</p>
            </span>
          </li>
          <div class="modalLikes__containerRow">
            <div class="modalLikes__link" @click="getMoreLikes" v-if="hasMore && !loadLikes">Ver más</div>
          </div>
          <div class="spinner" v-if="loadLikes"></div>
        </ul>
      </template>
      <template v-slot:footer>
        <span></span>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "./Modal.vue";
import Iconic from "@/plugins/iconic/iconic.vue";

export default {
  components: { Modal, Iconic },
  props: { idPost: String, endpoint: String },
  data() {
    return {
      likes: [],
      hasMore: false,
      loadLikes: false,
      page: 0,
    };
  },
  methods: {
    async getLikes() {
      this.loadLikes = true;
      const { data } = await this.$axios.get(`${this.endpoint}?id=${this.idPost}&page=${this.page}`);
      this.hasMore = data.hasMore;
      this.loadLikes = false;
      return data.data;
    },
    async getMoreLikes() {
      if (this.hasMore && !this.loadLikes) {
        this.page++;
        const likesLocal = await this.getLikes();
        this.likes.push(...likesLocal);
      }
    },
    addScrollEvent() {
      const body = document.getElementById("modalLikes__body");
      this.$global.clickOnBottom({
        el: body,
        onBottom: this.getMoreLikes,
        gap: 10,
      });
    },
    async initialLoad() {
      this.likes = await this.getLikes();
    },
    exitWithEsc(e) {
      if (e.key === "Escape") {
        this.$emit("close", false);
      }
    },
  },
  async beforeMount() {
    await this.initialLoad();
    document.body.classList.add("onmodal");
  },
  async mounted() {
    await this.addScrollEvent();
    document.addEventListener("keyup", this.exitWithEsc);
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.exitWithEsc);
    document.body.classList.remove("onmodal");
  },
};
</script>

<style lang="scss">
.modalLikes {
  .modal-g {
    width: 100%;
    height: 100%;
  }
  .modal-g__wrapper {
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
  &__avatar {
    width: 1.7rem;
    height: 1.7rem;
    flex-shrink: 0;
  }
  &__body {
    margin: 0;
    scrollbar-width: thin;
    overflow-y: auto;
    max-height: 250px;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    &:empty:before {
      content: attr(placeholder);
      color: #c8c8c8;
    }
  }
  &__container,
  &__header {
    width: 100%;
  }
  &__containerRow {
    @include Row(flex-start);
    &--end {
      justify-content: flex-end;
    }
  }
  &__close {
    font-size: 0.4em;
    color: $primary-color;
    cursor: pointer;
  }
  &__avatar {
    margin-right: $mpadding/2;
  }
  &__row:last-child &__name {
    border-bottom: none;
  }
  &__name {
    width: 80%;
    padding: $mpadding/2;
    border-bottom: 1px solid $alto;
  }
  &__link {
    appearance: none;
    margin: auto;
    margin-top: $mpadding;
    padding: 0 $mpadding/2;
    text-align: center;
    color: $primary-color;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (min-width: $mobile_s_width) {
    .modal-g__container {
      top: 10%;
    }
  }
}
</style>
