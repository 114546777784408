<script>
  import CommentsInput from "./CommentsInput.vue";
  import CommentsList from "./CommentsList.vue";
  import { mapGetters } from "vuex";
  import { socket } from "@/socket";
  import { EventBus } from "@/assets/js/eventBus";

  export default {
    name: "Comments",
    components: { CommentsInput, CommentsList },
    props: {
      isBond: { type: Boolean, default: false },
      closeModal: { type: Function, required: true },
      newId: { type: String, required: true },
    },
    data() {
      return {
        comments: [],
        loading: false,
        hasMore: true,
        page: 0,
        clean: false,
        list: null,
        caret: null,
        responsing: "",
        commentResponse: {},
      };
    },
    computed: {
      ...mapGetters("applications", {
        getAppByName: "getAppByName",
      }),
      app() {
        return this.getAppByName("autologin");
      },
      initSocketEvents() {
        if (this.$socketState) {
          if (!this.isBond) socket.on("likeComments", this.updateLikes);
          else socket.on("likeCommentsBonds", this.updateLikes);
        }
      },
    },
    methods: {
      scrollToComments() {
        this.$nextTick(() => {
          const inputElement = document.querySelector(".comment-input");
          inputElement.focus();
        });
      },
      responseComment(response) {
        const { user, ...others } = response;
        if (response.commentId) {
          const userName = this.$userData.role === "model" ? this.$userData.artisticName : this.$userData.user;
          this.commentResponse = { ...others, user: userName };
        }
        this.responsing = user;
        setTimeout(() => {
          this.responsing = "";
        }, 500);
      },
      async postComment() {
        this.clean = true;
        this.responsing = "";
        this.commentId = "";
        await this.getComments();
        this.list.scrollTop = 0;
      },
      async getComments() {
        if (!this.hasMore && !this.clean) return;
        this.loading = true;
        if (this.clean) this.page = 0;

        const { data } = await this.$axios.get(`${this.app.api}${!this.isBond ? "/news" : "/bonds"}/comments?${!this.isBond ? "newId" : "bondId"}=${this.newId}&page=${this.page}`);
        this.hasMore = data.hasMore;
        this.loading = false;
        this.page++;
        this.clean ? (this.comments = data.comments) : (this.comments = [...this.comments, ...data.comments]);
        this.clean = false;
      },
      closeOnEsc({ key }) {
        if (key === "Escape") {
          this.closeModal();
        }
      },
      updateLikes(like) {
        const userId = this.$userToken._id;
        this.comments = this.comments.map((comment) => {
          let indexResponse = -1;
          if (comment.responses) indexResponse = comment.responses.findIndex((res) => res._id === like.like.commentId);
          if (comment._id === like.like.commentId) {
            if (like.status === "like") {
              comment.likes.total++;
              comment.likes.likesUser.unshift(like.like);
              if (like.like.userId._id === userId) comment.likes.isMyLike = true;
            }
            if (like.status === "unlike") {
              const i = comment.likes.likesUser.findIndex((_like) => _like.userId._id === like.like.userId);
              comment.likes.total--;

              if (like.like.userId === userId) comment.likes.isMyLike = false;
              if (i !== -1) {
                comment.likes.likesUser.splice(i, 1);
              }
            }
          } else if (indexResponse > -1) {
            if (like.status === "like") {
              comment.responses[indexResponse].likes.total++;
              comment.responses[indexResponse].likes.likesUser.unshift(like.like);
              if (like.like.userId._id === userId) comment.responses[indexResponse].likes.isMyLike = true;
            }
            if (like.status === "unlike") {
              const i = comment.responses[indexResponse].likes.likesUser.findIndex((_like) => _like.userId._id === like.like.userId);
              comment.responses[indexResponse].likes.total--;

              if (like.like.userId === userId) comment.responses[indexResponse].likes.isMyLike = false;
              if (i !== -1) {
                comment.responses[indexResponse].likes.likesUser.splice(i, 1);
              }
            }
          }
          return comment;
        });
      },
      addEvents() {
        this.$global.clickOnBottom({
          el: this.list,
          onBottom: this.getComments,
        });
        document.addEventListener("keydown", this.closeOnEsc);
      },
    },

    async mounted() {
      EventBus.$on("scroll-to-comments", this.scrollToComments);
      this.list = document.querySelector(".comments-list");
      this.addEvents();
      await this.getComments();
    },
    beforeDestroy() {
      EventBus.$off("scroll-to-comments", this.scrollToComments);
      document.removeEventListener("keydown", this.closeOnEsc);
      socket.off("likeBonds", this.updateLikes);
    },
  };
</script>

<template>
  <div class="comments" fcenter v-bind="initSocketEvents">
    <div class="comments-container news_comments">
      <i class="fa fa-times comments-close" @click="closeModal" />
      <div class="comments-list-container">
        <CommentsList :isBond="isBond" class="comments-list" :comments="comments" :newId="newId" :app="app" @responsing="responseComment" @sendComment="postComment"></CommentsList>
        <div spinner v-show="loading"></div>
      </div>
      <CommentsInput :isBond="isBond" :newId="newId" :app="app" :responsing="responsing" :commentResponse="commentResponse" @sendComment="postComment" />
    </div>
  </div>
</template>

<style lang="scss">
  .comments {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    &__options {
      @include Row(flex-start);
      padding: 0 60px;
    }
    &-bar {
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
      }
      &:empty:before {
        content: attr(placeholder);
        color: #c8c8c8;
      }
    }

    &-container {
      width: 100%;
      max-width: 579px;
      max-height: 470px;
      background: white;
      border-radius: 6px;
      position: relative;
      padding: 40px 10px 0 10px;
      @media (min-width: $tablet-width) {
        padding: 30px 40px 0 20px;
      }
    }
    &-close {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 20px;
      color: $primary-color;
      cursor: pointer;
    }

    &-list {
      scrollbar-width: thin;
      overflow-y: auto;
      max-height: 306px;
      margin-top: 20px;
      padding-bottom: 20px;
      background-color: $white;
      &-container {
        position: relative;
        padding: 0 0 20px 0;
        [spinner] {
          position: absolute;
          top: 92%;
        }
      }
    }
    @include mobileMWidth() {
      &-container {
        width: 90%;
      }
    }
    @include tabletWidth() {
      &-container {
        max-height: 85vh;
      }
      &-list {
        max-height: 400px;
      }
    }
  }
</style>
