<script>
import comment from "./Comment.vue";

export default {
  name: "CommentsList",
  components: { comment },
  props: ["comments", "newId", "app", "isBond"],
  data() {
    return {
      viewComments: {},
      keyComents: 0,
    };
  },
  methods: {},
};
</script>
<template>
  <div class="comments-bar" v-show="comments.length">
    <div class="comment-container" v-for="(comment, idx) in comments" :key="`${comment._id}-father-${idx}`">
      <comment
        :comment="comment"
        :isBond="isBond"
        :newId="newId"
        :app="app"
        @sendComment="$emit('sendComment')"
        @responsing="$emit('responsing', { ...$event, commentId: comment._id })"
        :key="keyComents"
      >
        <div
          class="comments__wrapper "
          v-if="comment.responses && comment.responses.length && !viewComments[comment._id]"
          @click="(viewComments[comment._id] = true), keyComents++"
        >
          {{ `Ver ${comment.responses.length} ${comment.responses.length > 1 ? "respuestas" : "respuesta"} más...` }}
        </div>
        <div class="comments-bar" v-else-if="comment.responses && comment.responses.length && viewComments[comment._id]">
          <div class="comment-container comment-container--padding" v-for="commentRes in comment.responses" :key="`${commentRes._id}-child-${idx}`">
            <comment
              class="comment__responseBlock"
              :comment="commentRes"
              :isBond="isBond"
              :newId="newId"
              :app="app"
              :notResponse="true"
              @sendComment="$emit('sendComment')"
              @responsing="$emit('responsing', { ...$event, commentId: comment._id })"
            />
          </div>
        </div>
      </comment>
    </div>
  </div>
</template>
<style lang="scss">
$sangria: 36px;
.comments {
  &-comment {
    display: grid;
    grid-template-columns: $sangria 1fr 20px;
    column-gap: 8px;
    padding: 0px 10px;
    justify-content: flex-end;
  }
  &__wrapper {
    padding: 0 0 0 60px;
    color: $chicago;
    font-size: 0.8em;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  &__subcontainer {
    flex: 1 0;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  &__response {
    position: relative;
    top: 1.3px;
    cursor: pointer;
  }
  &__options {
    @include Row(flex-start, flex-end);
  }
  &__like {
    align-self: center;
  }
  .likes__total,
  &__response {
    font-size: 11px;
    line-height: 16.8px;
    &:hover {
      text-decoration: underline;
    }
  }
  .comment {
    &__responseBlock {
      width: 90%;
      max-width: 90%;
    }
    &-avatar {
      width: $sangria;
      height: $sangria;
      font-size: 12px;
    }
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 4px;
    }
    &-name {
      max-width: 110px;
      font-weight: bold;
      font-size: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    &-date {
      font-size: 12px;
      color: #9d9d9d;
    }
    &-text {
      font-size: 14px;
      word-break: break-word;
      margin-bottom: 10px;
    }
    &-content {
      min-width: 160px;
      background: #9d9d9d1c;
      padding: 8px 12px;
      border-radius: 15px;
    }
    &-container {
      @include Row(flex-end, center);
      padding: 8px 12px;
      &--padding {
        padding: 8px 0 8px 48px;
        .comments-comment {
          grid-template-columns: 30px 1fr 20px;
        }
        .comment-avatar {
          width: 30px;
          height: 30px;
          top: 6px;
        }
      }
    }
    @include mobileLWidth() {
      &-name {
        max-width: 140px;
      }
    }
    @include pocketWidth() {
      &-name {
        max-width: unset;
      }
    }
  }
}
</style>
