<script>
import CommentsInput from "./CommentsInput.vue";
import likes from "@/components/likes/Likes.vue";
import { mapGetters } from "vuex";

export default {
  name: "Comment",
  props: {
    comment: { type: Object, required: true },
    newId: { type: String, required: true },
    isResponsing: { type: Boolean, default: false },
    notResponse: { type: Boolean, required: false },
    isBond: { type: Boolean, default: false },
  },
  components: { CommentsInput, likes },
  data() {
    return {
      isLike: "",
      isResponse: false,
    };
  },

  computed: {
    getUser() {
      return this.comment?.user?.role === "model" ? this.comment?.user?.artisticName : this.comment?.user?.user;
    },
  },
  methods: {
    onLike(id) {
      this.isLike = id;
      setTimeout(() => {
        this.isLike = "";
      }, 100);
    },
    onResponse() {
      this.$emit("responsing", {
        user: this.getUser,
        userId: this.comment.user._id,
      });
    },
  },
};
</script>

<template>
  <div class="comments__subcontainer">
    <div class="comments-comment">
      <Avatar class="comment-avatar" :user="comment.user" />
      <div class="comment-content">
        <div class="comment-header">
          <span class="comment-name">{{ getUser }}</span>
          <span class="comment-date">{{ comment.timeLapsed }}</span>
        </div>
        <div class="comment-text">
          <span v-html="comment.comment"></span>
        </div>
      </div>
      <div class="comment-like_icon">
        <iconic
          :class="['comments__like', 'likes__ico', comment.likes.isMyLike ? 'likes__ico--fill' : '']"
          :name="!comment.likes.isMyLike ? 'heart_regular' : 'heart_solid'"
          @click.native="onLike(comment._id)"
        />
      </div>
    </div>
    <div class="comments__options ">
      <likes
        :likes="comment.likes"
        :postId="comment._id"
        :nameEvent="!isBond ? 'likeComments' : 'likeCommentsBonds'"
        :endpoint="!isBond ? '/news/likescomments' : '/bonds/likescomments'"
        :style2="true"
        :isLike="isLike"
        v-show="comment.likes.total > 0"
      />
      <span class="comments__response" @click="onResponse">Responder</span>
    </div>
    <slot></slot>
  </div>
</template>
