<script>
  import Emojis from "@/components/widgets/Emojis.vue";
  import { mapActions } from "vuex";
  export default {
    components: { Emojis },
    name: "CommentInput",
    props: ["newId", "app", "commentResponse", "responsing", "isBond", "participant"],
    data() {
      return {
        emojis: false,
        sending: false,
        userResponding: "",
        currentComent: "",
      };
    },
    watch: {
      responsing(newValue) {
        if (newValue) {
          this.userResponding = newValue;
          const input = this.$refs.input;
          input.innerHTML = "";
          input.focus();

          document.insertAdjacentHTML("beforeend", `@${newValue} <span></span>`);
        }
      },
    },
    methods: {
      ...mapActions("comments", ["createNewcomment"]),
      addEmoji(emoji) {
        const input = this.$refs.input;
        input.focus();
        input.execCommand("beforeend", false, emoji);
      },
      showEmojis: async function() {
        this.emojis = !this.emojis;
        if (this.emojis) {
          const inputElement = document.querySelector(".input-container input");
          await this.sleep(50);
          const box = document.querySelector(".input-container");
          box.scrollIntoView({ behavior: "smooth" });
          if (inputElement) {
            inputElement.focus();
          }
        }
      },
      async postComment() {
        const comment = this.currentComent;
        const clean = comment
          .replaceAll("&nbsp;", "")
          .replaceAll("<div><br></div>", "")
          .replaceAll("<div></div>", "")
          .replaceAll("<div> </div>", "")
          .trim();
        if (!comment || !clean) return;
        const isResponse = comment.match(new RegExp(`@${this.userResponding}`));
        const hasResponse = !!isResponse?.at(0);
        const response = comment.replace(
          new RegExp(`@${this.userResponding}`),
          `<span class="responseComment" style="color: #bd0909; font-size=0.9em;">${isResponse?.at(0)?.trim()}&nbsp;</span><span></span>`
        );
        const info = { newId: this.newId, comment: response, commentResponse: hasResponse ? this.commentResponse : undefined };
        if (info.newId) {
          const infos = { bondId: this.newId, comment: response, commentResponse: hasResponse ? this.commentResponse : undefined };
          this.sending = true;
          await this.$axios.post(`${this.app.api}${!this.isBond ? "/news" : "/bonds"}/comments`, !this.isBond ? info : infos);
          this.currentComent = "";
          this.$emit("sendComment");
          this.sending = false;
        } else {
          let data = {
            participant: this.participant._id,
            user: this.$user._id,
            comment: comment,
          };
          await this.createNewcomment(data);
          this.currentComent = "";
          this.$emit("sendComment");
          this.sending = false;
        }
      },
    },
  };
</script>

<template>
  <div class="comments-input">
    <Emojis class="emoji-comments" :target="`.comment-input`" v-if="emojis" />
    <div class="input-container">
      <Avatar class="input-avatar" :user="$userData" />
      <input class="input-input comments-bar comment-input" v-model="currentComent" v-on:keyup.enter="postComment" ref="input" contenteditable="true" placeholder="Escribe comentario" />
      <div class="input-actions">
        <a class="input-emojis" @click="showEmojis">
          <iconic :name="!emojis ? 'smile' : 'close'" class="input-emojis" />
        </a>
      </div>
    </div>
    <button v-if="!sending" class="input-send" @click.prevent="postComment"><iconic class="input-sendIcon" name="buttonSend" /></button>
    <div v-else spinner></div>
  </div>
</template>

<style lang="scss">
  .comments {
    &-input {
      @include Flex(row, space-between, center);
      width: 100%;
      font-size: 12px;
      border-radius: 25px;
      background: #9d9d9d1c;
      padding: 14px 10px;
    }
    .input {
      &-actions {
        display: flex;
        align-items: center;
        margin-left: auto;
        user-select: none;
        [spinner] {
          width: 20px;
          height: 20px;
          margin: 0 18px;
        }
      }
      &-avatar {
        width: 36px;
        height: 36px;
        min-width: 36px;
        min-height: 36px;
        font-size: 12px;
        margin-right: $mpadding/2;
        margin-top: 2px;
      }
      &-send {
        @include Flex();
        padding: 10px;
        color: white;
        background: $primary-color;
        outline: none;
        border: none;
        border-radius: 50%;
        margin-left: $space-16;
        transition: all 0.3s ease-in-out;
        &:hover {
          transform: scale(1.05);
        }
      }
      &-sendIcon {
        color: white;
        width: 26px;
        height: 26px;
        font-size: 20px;
      }

      &-container {
        display: flex;
        border-radius: 25px;
        border: 0.6px solid #9d9d9d;
        padding: 6px 12px;
        width: 100%;
        max-width: 1064px;
        background: white;
        align-items: center;
        [contenteditable] {
          border: none;
          background: transparent;
          width: 30%;
          outline: none;
          max-height: 72px;
          overflow-y: scroll;
          word-break: break-word;
          font-size: 14px;
          scrollbar-width: none;
          flex: auto;
          cursor: text;
        }
        i {
          font-size: 18px;
          color: #9d9d9d;
          margin: 0 6px;
        }
      }
      &-emojis {
        cursor: pointer;
        position: relative;
        user-select: none;
        display: flex;
        align-items: center;
        i,
        svg {
          user-select: none;
          max-width: 15px;
          max-height: 15px;
        }
        &-content {
          user-select: none;
          // width: 134px;
          font-size: 14px;
          background-color: white;
          text-align: center;
          border-radius: 6px;
          padding: 5px;
          position: absolute;
          z-index: 2;
          top: 150%;
          right: -40%;
          box-shadow: $dshadow;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          &::after {
            content: "";
            position: absolute;
            bottom: 100%;
            right: 16%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent white transparent;
          }
          span {
            cursor: pointer;
          }
        }
        &-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
      }
    }
    .emoji-comments {
      padding: 0 0 $mpadding 0;
      font-size: 150%;
    }
  }
</style>
