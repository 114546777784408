<template>
  <div class="likes likes__contentRow">
    <div class="likes__icoContainer likes__contentRow">
      <iconic
        :class="['likes__ico', likes.isMyLike ? 'likes__ico--fill' : '']"
        :name="!likes.isMyLike ? 'heart_regular' : 'heart_solid'"
        v-if="!style2 && !loadLikes"
        @click.native="onLike"
      />
      <div class="spinner" v-if="loadLikes"></div>
      <div class="likes__icoText tooltip2" @click="showNewsLikes">
        <span class="likes__total" v-if="likes.total">{{ getTotal }}</span>
        <span class="likes__tooltip tooltiptext tool-auto">
          <span class="likes__tooltipOption" v-for="(user, idx) in usersLikesSlice" :key="idx">
            {{ user.userId.artisticName || user.userId.user }}
          </span>
          <a class="likes__tooltipOption likes__tooltipOption--link" href="#" v-if="usersMore">y {{ usersMore }} personas más</a>
        </span>
      </div>
    </div>
    <modalLikes :idPost="postId" :endpoint="endpoint" v-if="isShowLikes" @close="isShowLikes = $event" />
  </div>
</template>
<script>
import modalLikes from "@/components/modals/ModalLikes";
import { socket } from "@/socket";

export default {
  name: "likes",
  components: {
    modalLikes,
  },
  props: {
    news: { type: Object, default: () => ({}) },
    likes: { type: Object, default: () => ({ total: 0, likesUser: [] }) },
    postId: String,
    nameEvent: String,
    endpoint: String,
    style2: Boolean,
    isLike: String,
  },
  data() {
    return {
      isShowLikes: false,
      loadLikes: false,
    };
  },
  computed: {
    usersLikesSlice() {
      const slice = this.likes.likesUser.slice(0, 6);
      return slice.length ? slice : this.likes.likesUser;
    },
    socketIsReady() {
      return !!this.$store.getters["connectedUsers/getSocketState"];
    },
    usersMore() {
      const slice = this.likes.likesUser.slice(0, 6);
      return slice.length ? this.likes.total - slice.length : 0;
    },
    getTotal() {
      if (this.style2) return `${this.likes.total} Me gusta`;
      return this.likes.total;
    },
  },
  watch: {
    isLike(newValue) {
      if (newValue === this.postId) this.onLike();
    },
  },
  methods: {
    showNewsLikes: function() {
      if (!this.news || !this.news._id) {
        this.isShowLikes = true;
      } else {
        this.$store.state.news.NewsLikesVisor = this.news;
      }
    },
    onLike() {
      if (navigator.onLine && this.socketIsReady) {
        this.loadLikes = true;
        socket.emit(this.nameEvent, { id: this.postId });
        setTimeout(() => {
          this.loadLikes = false;
        }, 800);
      }
    },
  },
};
</script>
<style lang="scss">
.spinner {
  width: 20px;
  height: 20px;
  margin: auto;
}
.likes {
  &__contentRow {
    @include Row(flex-start);
    min-width: 43px;
  }
  &__icoContainer {
    user-select: none;
  }
  &__ico {
    font-size: 1.2em;
    cursor: pointer;
    &--fill {
      color: $primary_color;
    }
  }
  &__icoText {
    padding: 0 $mpadding/2;
    position: relative;
    font-size: 14px;
    cursor: pointer;
  }
  &__tooltip {
    @include Column(flex-start, flex-start);
  }
  &__tooltipOption {
    width: auto;
    max-width: 86px;
    margin: 0;
    overflow: hidden;
    font-size: 9px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $white;
    &--link {
      &:hover {
        color: $white;
      }
    }
  }
  .tooltip2 .tooltiptext {
    pointer-events: auto;
    max-width: 90px;
    text-align: left;
    cursor: default;
  }
  .tooltip2:hover .tooltiptext {
    visibility: hidden;
  }
  @media screen and (min-width: 768px) {
    .tooltip2:hover .tooltiptext {
      visibility: visible;
    }
  }
}
</style>
